import React from 'react';
import SWCTabs from '../../components/SWCTabs';
import {ADMIN_SETTINGS} from '../../components/PathConstants';

export default function AdminCloudSettingsTabs({activeTabPath, children}) {

  const tabsInfo = [
    ADMIN_SETTINGS.swcDetails,
    ADMIN_SETTINGS.translationTable,
    ADMIN_SETTINGS.notifications,
    ADMIN_SETTINGS.complianceLogging,
    ADMIN_SETTINGS.activityStream,
  ];

  return (<SWCTabs activeTabPath={activeTabPath} tabsInfo={tabsInfo}>
    {children}
  </SWCTabs>);
}
