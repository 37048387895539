import React, {useState} from 'react';
import {Navigate, Route, Routes, useNavigate} from 'react-router-dom';
import Container from '@mui/material/Container';
import {SnackbarProvider} from 'notistack';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import {RateReviewOutlined, SearchOutlined} from '@mui/icons-material';
import {useAuth} from './react-auth-wrapper';
import ErrorBoundary from './components/ErrorBoundary';
import MenuAppBar from './menu/MenuAppBar';
import Profile from './pages/Profile';
import {PageMetaContext} from './contexts/PageMetaContext';
import {useNotifications} from './contexts/NotificationsContext';
import Translations from './pages/admin/Translations';
import Files from './pages/Files';
import LoadingIcon from './components/LoadingIcon';
import {PermissionsContextProvider} from './contexts/PermissionsContext';
import UserAvatar from './components/UserAvatar';
import LinkToCloud from './components/LinkToCloud';
import Footer from './components/Footer';
import Welcome from './components/Welcome';
import SWCDetailsTab from './menu/admin/SWCDetailsTab';
import ProductsOverview from './pages/ProductsOverview';
import BreadcrumbsMenu from './components/breadcrumbsMenu/BreadcrumbsMenu';
import TradeComplianceLogging from './pages/admin/TradeComplianceLogging';
import RootNodeUrls from './components/RootNodeUrls';
import AdminOverview from './pages/admin/AdminOverview';
import Notifications from './pages/admin/Notifications';
import AdminNotifications from './components/AdminNotifications';
import CloudFiles from './pages/admin/CloudFiles/CloudFiles';
import NotFound from './pages/NotFound';
import BuildsBulkTasks from './pages/admin/BuildsBulkTasks/BuildsBulkTasks';
import BuildsBulkOperations from './pages/admin/BuildsBulkOperations/BuildsBulkOperations';
import BuildsBulkTaskDetail from './pages/admin/BuildsBulkTasks/BuildsBulkTaskDetail';
import UserAvatarMenu from './components/Impersonation/UserAvatarMenu';
import {brandColor} from './contexts/ThemeContext';
import BuildSearch from "./components/BuildSearch/BuildSearch";
import BuildDetailPage from "./pages/BuildDetailPage";
import BuildListPage from "./pages/BuildListPage";
import BuildActivitySearchAdminTab from "./pages/admin/BuildActivitySearchAdminTab";
import Can from "./components/Can";
import ProductUpload from "./pages/admin/ProductUpload";
import StartProductUpload from "./pages/admin/ProductUpload/wizard/StartProductUpload";
import ComplianceLog from "./pages/admin/ComplianceLog/ComplianceLog";
import styled from "@emotion/styled";

const StyledTooltip = styled(Tooltip)`
  margin-right: ${({theme}) => theme.spacing(2)};
`;

const StyledMain = styled.main`
  flex-grow: 1;
  height: 100vh;
  overflow: auto;
`;

const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
`;
const StyledToolbarContent = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const StyledToolbarEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const StyledContainer = styled(Container)`
  padding-top: ${({theme}) => theme.spacing(4)};
  padding-bottom: ${({theme}) => theme.spacing(4)};
  min-height: calc(100vh - 40px - 64px); // minus Footer height, minus Toolbar height
`;

const StyledContainerEnd = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: ${({theme}) => theme.spacing(2)};
  margin-bottom: ${({theme}) => theme.spacing(2)};
`;

function App() {
  const {notificator} = useNotifications();
  const {
    logout, loading, isAuthenticated, loginWithRedirect,
  } = useAuth();
  const [title, _setTitle] = useState('');
  const [cloudKey, _setCloudKey] = useState('');
  const [rootNodeUrls, _setRootNodeUrls] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  if (loading) {
    return (<LoadingIcon color={brandColor}/>);
  }

  if (!isAuthenticated) {
    console.log('[App] redirect', window.location.pathname);
    // TODO Keycloak app state seems to be managed internally, we might remove that
    loginWithRedirect({appState: {targetUrl: window.location.pathname}});
    return (<LoadingIcon color={brandColor}/>);
  }

  function setTitle(value) {
    console.log('[App]', 'Setting title', value);
    _setTitle(value);
  }

  function setCloudKey(value) {
    console.log('[App]', 'Setting cloudKey', value);
    _setCloudKey(value);
  }

  function setRootNodeUrls(value) {
    console.log('[App]', 'Setting rootNodeUrls', value);
    _setRootNodeUrls(value);
  }

  function handleAccountMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleAccountMenuClose() {
    setAnchorEl(null);
  }

  function goTo(path, replaceBrowserHistory = false) {
    navigate(path, {replace: replaceBrowserHistory});
  }

  function handleProfile() {
    goTo('/profile');
    handleAccountMenuClose();
  }

  function handleLogout() {
    logout({
      returnTo: window.location.origin,
    });
  }

  return (
    <div>
      <ErrorBoundary>
        <SnackbarProvider maxSnack={3}>
          <PermissionsContextProvider>
            <PageMetaContext.Provider value={{
              title,
              setTitle,
              cloudKey,
              setCloudKey,
              setRootNodeUrls,
              goTo,
            }}
            >
              <MenuAppBar>
                <ErrorBoundary>
                  <StyledMain id="app-main">
                    <StyledToolbar>
                      <StyledToolbarContent>
                        <Can
                          perform="READ_BUILD"
                          yes={() => (
                            <StyledTooltip title="Search for Builds">
                              <Button
                                aria-label="Search"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                color="inherit"
                                onClick={() => goTo("/search?&buildTypes=RELEASE,CS&pageSize=25")}
                                startIcon={<SearchOutlined/>}
                              >
                                Search
                              </Button>
                            </StyledTooltip>
                          )}
                        />
                      </StyledToolbarContent>
                      <StyledToolbarEnd>
                        <StyledTooltip title="Give feedback">
                          <Button
                            aria-label="Feedback"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                            id="software-center-jira-feedback-issue-collector-trigger"
                            startIcon={<RateReviewOutlined/>}
                          >
                            Feedback
                          </Button>
                        </StyledTooltip>
                        <IconButton
                          aria-label="Account of current user"
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          onClick={handleAccountMenu}
                          color="inherit"
                          size="large"
                        >
                          <UserAvatar/>
                        </IconButton>
                        <UserAvatarMenu
                          id="menu-appbar"
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          open={open}
                          onClose={handleAccountMenuClose}

                          logout={handleLogout}
                          profile={handleProfile}
                        />
                      </StyledToolbarEnd>
                    </StyledToolbar>
                    <StyledContainer maxWidth="xl">
                      {notificator('commonNotificator')}
                      <AdminNotifications/>
                      <BreadcrumbsMenu/>
                      <StyledContainerEnd>
                        <Typography
                          variant="h6"
                          sx={{
                            borderBottomStyle: 'solid',
                            borderWidth: (theme) => theme.spacing(0.5),
                            borderColor: brandColor,
                          }}
                        >
                          {title}
                        </Typography>
                        {cloudKey && <LinkToCloud cloudKey={cloudKey}/>}
                        <RootNodeUrls urls={rootNodeUrls}/>
                      </StyledContainerEnd>
                      <Routes>
                        <Route path="/" element={<Welcome/>}/>
                        <Route path="/welcome" element={<Welcome/>}/>

                        <Route path="/search" element={<BuildSearch/>}/>
                        <Route
                          path="/download/:category/builds/:group/:product/details/mainversion/:mainVersion/os/:os/version/:version/type/:type"
                          element={<BuildDetailPage/>}
                        />
                        <Route path="/download/:category/builds/:group/:product" element={<BuildListPage/>}/>
                        <Route path="/download/:category/builds/:group" element={<ProductsOverview/>}/>
                        <Route path="/download/:category/builds" element={<ProductsOverview/>}/>
                        <Route path="/download/:category/files/:bucketId/:path" element={<Files/>}/>
                        <Route path="/download/:category/files/:bucketId" element={<Files/>}/>
                        <Route path="/download/:category" element={<ProductsOverview/>}/>
                        <Route path="/download/" element={<ProductsOverview/>}/>

                        <Route path="/profile" element={<Profile/>}/>
                        <Route path="/profile/:tab" element={<Profile/>}/>
                        <Route path="/admin" element={<AdminOverview/>}/>
                        <Route path="/admin/cloud-settings" element={<SWCDetailsTab/>}/>
                        <Route path="/admin/cloud-settings/swc-details" element={<SWCDetailsTab/>}/>
                        <Route path="/admin/cloud-settings/translations" element={<Translations/>}/>
                        <Route path="/admin/cloud-settings/notifications" element={<Notifications/>}/>
                        <Route path="/admin/download-overview/" element={<BuildsBulkTasks/>}/>
                        <Route
                          path="/admin/cloud-settings/trade-compliance-logging"
                          element={<TradeComplianceLogging/>}
                        />
                        <Route
                          path="/admin/cloud-settings/activity-stream"
                          element={<BuildActivitySearchAdminTab/>}
                        />
                        <Route path="/admin/cloud-files" element={<CloudFiles/>}/>
                        <Route
                          path="/admin/cloud-files/:bucketId/:path"
                          element={<CloudFiles/>}
                        />
                        <Route path="/admin/cloud-files/:bucketId" element={<CloudFiles/>}/>
                        <Route
                          path="/admin/download-overview/bulk-tasks/:taskId"
                          element={<BuildsBulkTaskDetail/>}
                        />
                        <Route path="/admin/download-overview/bulk-tasks" element={<BuildsBulkTasks/>}/>
                        <Route path="/admin/download-overview/bulk-operations"
                               element={<BuildsBulkOperations/>}/>
                        <Route path="/admin/product-upload" element={<ProductUpload/>}/>
                        <Route path="/admin/product-upload/start/:taskId" element={<StartProductUpload/>}/>
                        <Route path="/admin/product-upload/start" element={<StartProductUpload/>}/>
                        <Route path="/admin/compliance-log" element={<ComplianceLog/>}/>
                        <Route path="*" element={<Navigate to="/notFound" replace/>}/>
                        {/* Not found router have to be last in the list of routers */}
                        <Route path="/notFound" element={<NotFound/>}/>
                      </Routes>
                    </StyledContainer>
                    <Footer/>
                  </StyledMain>
                </ErrorBoundary>
              </MenuAppBar>
            </PageMetaContext.Provider>
          </PermissionsContextProvider>
        </SnackbarProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
