export const ADMIN_SETTINGS = {
  swcDetails: {name: 'SWC Details', path: '/admin/cloud-settings/swc-details'},
  translationTable: {name: 'Translation table', path: '/admin/cloud-settings/translations'},
  notifications: {name: 'Notifications', path: '/admin/cloud-settings/notifications'},
  complianceLogging: {
    name: 'Trade Compliance Logging',
    path: '/admin/cloud-settings/trade-compliance-logging'
  },
  activityStream: {name: 'Default Activity Stream', path: '/admin/cloud-settings/activity-stream'},
  buckets: {name: 'Buckets Overview', path: '/admin/cloud-files'},
};

export const CLOUD_OVERVIEW = {
  vms: {name: 'VM Overview', path: '/admin/cloud-overview/cloud-vms'},
  volumes: {name: 'Volume Overview', path: '/admin/cloud-overview/cloud-volumes'},
  billing: {name: 'Cloud Billing Overview', path: '/admin/cloud-overview/cloud-billing'},
};

export const ADMIN_DOWNLOADS = {
  bulkTasks: {name: 'Bulk Task Overview', path: '/admin/download-overview/bulk-tasks'},
  bulkOperationWizard: {name: 'Bulk Operation Wizard', path: '/admin/download-overview/bulk-operations'},
};

